<template>
  <div>
    <v-tabs class="mb-4" v-model="tab" color="accent" grow>
      <v-tab>
        Details
      </v-tab>
      <v-tab>
        Payment Information
      </v-tab>
      <v-tab>
        Landlord Information
      </v-tab>
      <v-tab>
        Previous Tenants Information
      </v-tab>
      <v-tab>
        Brewery Information
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <AddressInformation />
      </v-tab-item>
      <v-tab-item>
        <PaymentInfo />
      </v-tab-item>
      <v-tab-item>
        <LandlordInfo />
      </v-tab-item>
      <v-tab-item>
        <PreviousTenantInfo />
      </v-tab-item>
      <v-tab-item>
        <BreweryInfo />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import AddressInformation from "@/components/forms/address-information"
export default {
  components: {
    AddressInformation,
    PaymentInfo: () => import("@/components/forms/payment-info"),
    LandlordInfo: () => import("@/components/forms/landlord-info"),
    PreviousTenantInfo: () => import("@/components/forms/previous-tenant"),
    BreweryInfo: () => import("@/components/forms/brewery-info")
  },
  data: () => ({
    tab: null
  })
}
</script>
